.App {
  text-align: center;
}

.App {
  padding: 10px;
  background-color: #282c34;
  min-height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(12px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
